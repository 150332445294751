export enum PatternType {
  level1 = 'level1',
  level2 = 'level2',
  closedTrade = 'closedTrade',
  firstDeathDiedNoTrade = 'firstDeathDiedNoTrade',
  diedNoTrade = 'diedNoTrade',
  firstAssist = 'firstAssist',
  traded = 'traded',
  firstKillTradedDiedNoTrade = 'firstKillTradedDiedNoTrade',
  firstRound = 'firstRound',

  hypeTripleKill = 'hype_tripleKill',
  hypeQuadraKill = 'hype_quadraKill',
  hypeAceKill = 'hype_aceKill',
  hypeSixKill = 'hype_sixKill',

  hypeTripleAssist = 'hypeTripleAssist',
  hypeQuadraAssist = 'hypeQuadraAssist',
  hypePentaAssist = 'hypePentaAssist',
  hypeHexaAssist = 'hypeHexaAssist',

  clutch1v2 = 'clutch_1v2',
  clutch1v3 = 'clutch_1v3',
  clutch1v4 = 'clutch_1v4',
  clutch1v5 = 'clutch_1v5',

  AI = 'AI',
}
