export enum UserActivityType {
	audit_Lootopia_Enroll = "audit_Lootopia_Enroll",
	click_Lootopia_Enroll = "click_Lootopia_Enroll",
	click_Lootopia_Download = "click_Lootopia_Download",

	// App
	audit_App_Uninstall = "audit_App_Uninstall",
	audit_App_FirstLaunch = "audit_App_FirstLaunch",

	// Auth
	click_Auth_LoginWithOverwolf = "click_Auth_LoginWithOverwolf",
	click_Auth_LoginAsGuest = "click_Auth_LoginAsGuest",
	audit_Auth_Login = "audit_Auth_Login",
	audit_Auth_Logout = "audit_Auth_Logout",
	click_Auth_Logout = "click_Auth_Logout",
	audit_Auth_Signup = "audit_Auth_Signup",
	audit_Auth_ConvertAccount = "audit_Auth_ConvertAccount",

	// Share image
	click_ShareImage_Copy = "click_ShareImage_Copy",
	click_ShareImage_ChangeColor = "click_ShareImage_ChangeColor",
	click_ShareImage_ChangeAgent = "click_ShareImage_ChangeAgent",
	audit_ShareImage_Enter = "audit_ShareImage_Enter",
	audit_ShareImage_Leave = "audit_ShareImage_Leave",

	// Report a bug
	audit_ReportBug_Enter = "audit_ReportBug_Enter",
	audit_ReportBug_Leave = "audit_ReportBug_Leave",
	click_ReportBug_Send = "click_ReportBug_Send",
	audit_ReportBug = "audit_ReportBug",

	// Socials
	click_Socials_DiscordInvite = "click_Socials_DiscordInvite",

	// Settings
	audit_Settings_Enter = "audit_Settings_Enter",
	audit_Settings_Leave = "audit_Settings_Leave",
	click_Settings = "click_Settings",
	click_Settings_ChangeResolution = "click_Settings_ChangeResolution",
	click_Settings_ToggleInGameOverlay = "click_Settings_ToggleInGameOverlay",
	click_Settings_ToggleInGameUltStatus = "click_Settings_ToggleInGameUltStatus",
	click_Settings_ToggleSpikeTimer = "click_Settings_ToggleSpikeTimer",
	audit_Settings_Update = "audit_Settings_Update",

	// Guide
	audit_Guide_Enter = "audit_Guide_Enter",
	audit_Guide_Leave = "audit_Guide_Leave",
	click_Guide_SelectBehaviour = "click_Guide_SelectBehaviour",

	// Match History
	audit_MatchHistory_Enter = "audit_MatchHistory_Enter",
	audit_MatchHistory_Leave = "audit_MatchHistory_Leave",
	click_MatchHistory_Item = "click_MatchHistory_Item",

	// Homepage
	audit_Homepage_Enter = "audit_Homepage_Enter",
	audit_Homepage_Leave = "audit_Homepage_Leave",
	audit_PlayButton_Click = "audit_PlayButton_Click",

	// Match
	audit_Match_Start = "audit_Match_Start",
	audit_Match_End = "audit_Match_End",

	// Game
	audit_Game_Open = "audit_Game_Open",
	audit_Game_Close = "audit_Game_Close",
	audit_Game_Running = "audit_Game_Running",

	// Widgets
	audit_Widget_Like = "audit_Widget_Like",
	click_Widget_PerformanceStrengths_NextArrow = "click_Widget_PerformanceStrengths_NextArrow",
	click_Widget_PerformanceStrengths_ClickNavigationCircle = "click_Widget_PerformanceStrengths_ClickNavigationCircle",
	click_Widget_PerformanceWeaknesses_NextArrow = "click_Widget_PerformanceWeaknesses_NextArrow",
	click_Widget_PerformanceWeaknesses_ClickNavigationCircle = "click_Widget_PerformanceWeaknesses_ClickNavigationCircle",
	audit_Widget_View = "audit_Widget_View",

	// Share app
	click_ShareApp_Copy = "click_ShareApp_Copy",

	// Hotkeys
	audit_Hotkey_ToggleMatchLive = "audit_Hotkey_ToggleMatchLive",
	audit_Hotkey_SkipInGame = "audit_Hotkey_SkipInGame",

	// Info
	audit_Info_Enter = "audit_Info_Enter",
	audit_Info_Leave = "audit_Info_Leave",

	// Broadcast
	click_Broadcast_DismissMandatoryMessage = "click_Broadcast_DismissMandatoryMessage",

	// Updates
	click_Updates_ChangeTab = "click_Updates_ChangeTab",
	audit_Updates_Open = "audit_Updates_Open",

	// User Activity
	audit_Activity_PeriodEnd = "audit_Activity_PeriodEnd",

	// App Update
	click_AppUpdate_Update = "click_AppUpdate_Update",
	click_AppUpdate_Restart = "click_AppUpdate_Restart",

	// Match Live
	audit_MatchLive_Initialize = "audit_MatchLive_Initialize",

	// Ads
	audit_Ads_ContainerInitialize = "audit_Ads_ContainerInitialize",
	audit_Ads_VideoPlayerEvent = "audit_Ads_VideoPlayerEvent",
	audit_Ads_OverwolfEvent = "audit_Ads_OverwolfEvent",

	// Misc
	click_Misc_Contribute = "click_Misc_Contribute",

	// Onboarding
	audit_Onboarding_StatusChange = "audit_Onboarding_StatusChange",
	click_Onboarding_ClickSequence = "click_Onboarding_ClickSequence",

	// In - Game
	audit_InGame_AgentSelectEnter = "audit_InGame_AgentSelectEnter",
	audit_InGame_AgentSelectExit = "audit_InGame_AgentSelectExit",
	audit_InGame_TopAgentsForMapEnter = "audit_InGame_TopAgentsForMapEnter",
	audit_InGame_TopAgentsForMapExit = "audit_InGame_TopAgentsForMapExit",
	audit_UltHelper_Enter = "audit_UltHelper_Enter",
	audit_MatchSummary_EnterMinimized = "audit_MatchSummary_EnterMinimized",
	audit_MatchSummary_Show = "audit_MatchSummary_Show",
	audit_MatchSummary_Skip = "audit_MatchSummary_Skip",
	click_MatchSummary_Compare = "click_MatchSummary_Compare",
	click_MatchSummary_Performance = "click_MatchSummary_Performance",
	click_MatchSummary_Close = "click_MatchSummary_Close",
	hotkey_MatchSummary_Compare = "hotkey_MatchSummary_Compare",
	hotkey_MatchSummary_Performance = "hotkey_MatchSummary_Performance",
	hotkey_MatchSummary_Close = "hotkey_MatchSummary_Close",

	// Dashboards
	audit_Dashboard_AddItem = "audit_Dashboard_AddItem",
	click_Dashboard_AddContextAction = "click_Dashboard_AddContextAction",
	click_Dashboard_OpenContextActions = "click_Dashboard_OpenContextActions",
	audit_Dashboard_RemoveItemDrag = "audit_Dashboard_RemoveItemDrag",
	audit_Dashboard_RemoveItem = "audit_Dashboard_RemoveItem",
	audit_Dashboard_MoveItem = "audit_Dashboard_MoveItem",

	// Store
	audit_Store_Enter = "audit_Store_Enter",
	click_Store_Buy = "click_Store_Buy",
	click_Store_BuyDecline = "click_Store_BuyDecline",
	click_Store_BuyAccept = "click_Store_BuyAccept",
	audit_Store_BuySuccess = "audit_Store_BuySuccess",

	// Patterns
	audit_Pattern_Shown = "audit_Pattern_Shown",
	audit_Pattern_Skipped = "audit_Pattern_Skipped",
	audit_Pattern_Recognized = "audit_Pattern_Recognized",
	audit_Pattern_Matched = "audit_Pattern_Matched",

	// Game Session
	audit_GameSession_Checkpoint = "audit_GameSession_Checkpoint",
	audit_GameSession_Recommendation = "audit_GameSession_Recommendation",
	audit_GameSession_Recommendation_Skip = "audit_GameSession_Recommendation_Skip",
	audit_GameSession_Start = "audit_GameSession_Start",
	audit_GameSession_End = "audit_GameSession_End",
}
