import { z } from "zod";
import {
	ClientState,
	OWGameModes,
	ValorantMap,
	MatchOutcomes,
	ValorantAgent,
	OWAgentNamesList,
	OWValorantEvent,
	RankedMode,
	Ranks,
	RoundPhase,
	ScenesThatArentMaps,
	ShieldTiers,
	Team,
	AssistAgentToValorantAgent,
	ShopEventData,
	Weapons,
} from "./valorant";

export const ZWeapon = z.nativeEnum(Weapons);

const ZLethalWeapon = z.string();
export type ILethalWeapon = z.infer<typeof ZLethalWeapon>;

export const ZScoreSchema = z.object({
	won: z.number(),
	lost: z.number(),
});

export type IScore = z.infer<typeof ZScoreSchema>;

export const ZRoundReportSchema = z.object({
	damage: z.number(),
	hit: z.number(),
	headshot: z.number(),
	final_headshot: z.number(),
});

const ZRoundReportTransform = z
	.string()
	.transform((x) => ZRoundReportSchema.parse(JSON.parse(x)));
export type IRoundReport = z.infer<typeof ZRoundReportSchema>;

export const ZOWGameModeSchema = z.object({
	mode: z.nativeEnum(OWGameModes),
	custom: z.boolean(),
	ranked: z.nativeEnum(RankedMode),
});

export type IOWGameMode = z.infer<typeof ZOWGameModeSchema>;

export const ZPlayerNameSchema = z.object({
	gameTag: z.string().transform((x) => x.trim()),
	tagline: z.string().optional(),
});

export type IPlayerName = z.infer<typeof ZPlayerNameSchema>;

export const ZCharacter = z
	.string()
	.transform((x) => OWAgentNamesList.find((y) => y === x));
export type IAgent = z.infer<typeof ZCharacter>;

// Dont export this, this is for parsing the string to the schema and encapsulating overwolf
const ZPlayerFullName = z.string().transform((x) => {
	const [gameTag, tagline] = x.split("#");
	return ZPlayerNameSchema.parse({
		gameTag: gameTag.trim().replace("KAY/O", "KAYO"),
		tagline: tagline?.trim(),
	});
});

export type PlayerFullName = z.infer<typeof ZPlayerFullName>;

export const ZRosterPlayerSchema = z.object({
	player_id: z.string().optional(),
	name: ZPlayerFullName.optional(),
	character: ZCharacter.optional(),
	rank: z.nativeEnum(Ranks).nullable(),
	locked: z.boolean(),
	local: z.boolean(),
	teammate: z.boolean(),
});

export type IRosterPlayer = z.infer<typeof ZRosterPlayerSchema>;

const ZRosterTransform = z
	.string()
	.transform((x) => ZRosterPlayerSchema.parse(JSON.parse(x)));

export const ZScoreboardSchema = z.object({
	player_id: z.string().optional(),
	name: ZPlayerFullName.optional(),
	character: ZCharacter.optional(),
	teammate: z.boolean(),
	alive: z.boolean(),
	kills: z.number().nullable(),
	deaths: z.number().nullable(),
	assists: z.number().nullable(),
	money: z.number().nullable(),
	is_local: z.boolean(),
	shield: z.nativeEnum(ShieldTiers).optional(),
	weapon: ZLethalWeapon.optional(),
	ult_points: z.number().optional(),
	ult_max: z.number().optional(),
});

export type IScoreboard = z.infer<typeof ZScoreboardSchema>;

export const ZScoreboardTransform = z
	.string()
	.transform((x) => ZScoreboardSchema.parse(JSON.parse(x)));

const ZAssistSchema = z.string().transform((x) => {
	if (!x) {
		return undefined;
	}

	return AssistAgentToValorantAgent[x];
});

export const ZKillFeedSchema = z.object({
	attacker: z.string().transform((x) => x.replace("KAY/O", "KAYO")),
	victim: z.string().transform((x) => x.replace("KAY/O", "KAYO")),
	is_attacker_teammate: z.boolean(),
	is_victim_teammate: z.boolean(),
	weapon: ZLethalWeapon,
	headshot: z.boolean(),
	assist1: ZAssistSchema, // TODO : Get all the values for this
	assist2: ZAssistSchema,
	assist3: ZAssistSchema,
	assist4: ZAssistSchema,
	ult: z.string(), // z.nativeEnum(NonLethalUltimates) // TODO: Get all values for this
});

export type IKillFeed = z.infer<typeof ZKillFeedSchema>;

export const ZKillFeedTransform = z
	.string()
	.transform((x) => ZKillFeedSchema.parse(JSON.parse(x)));

const ZScoreTransform = z
	.string()
	.transform((x) => ZScoreSchema.parse(JSON.parse(x)));

export const ZValorantMap = z.nativeEnum(ValorantMap).optional().nullable();

export const ZMatchInfoSchema = z.object({
	pseudo_match_id: z.string().optional().nullable(),
	round_number: z
		.string()
		.transform((val) => parseInt(val))
		.optional()
		.nullable(),
	score: ZScoreTransform.optional().nullable(),
	round_phase: z.nativeEnum(RoundPhase).optional().nullable(),
	team: z.nativeEnum(Team).optional().nullable(),
	match_outcome: z.nativeEnum(MatchOutcomes).optional().nullable(),
	round_report: ZRoundReportTransform.optional().nullable(),
	game_mode: z
		.string()
		.transform((x) => ZOWGameModeSchema.parse(JSON.parse(x)))
		.optional()
		.nullable(),
	roster_0: ZRosterTransform.optional().nullable(),
	roster_1: ZRosterTransform.optional().nullable(),
	roster_2: ZRosterTransform.optional().nullable(),
	roster_3: ZRosterTransform.optional().nullable(),
	roster_4: ZRosterTransform.optional().nullable(),
	roster_5: ZRosterTransform.optional().nullable(),
	roster_6: ZRosterTransform.optional().nullable(),
	roster_7: ZRosterTransform.optional().nullable(),
	roster_8: ZRosterTransform.optional().nullable(),
	roster_9: ZRosterTransform.optional().nullable(),
	scoreboard_0: ZScoreboardTransform.optional().nullable(),
	scoreboard_1: ZScoreboardTransform.optional().nullable(),
	scoreboard_2: ZScoreboardTransform.optional().nullable(),
	scoreboard_3: ZScoreboardTransform.optional().nullable(),
	scoreboard_4: ZScoreboardTransform.optional().nullable(),
	scoreboard_5: ZScoreboardTransform.optional().nullable(),
	scoreboard_6: ZScoreboardTransform.optional().nullable(),
	scoreboard_7: ZScoreboardTransform.optional().nullable(),
	scoreboard_8: ZScoreboardTransform.optional().nullable(),
	scoreboard_9: ZScoreboardTransform.optional().nullable(),
	map: ZValorantMap,
});

export type IMatchInfo = z.infer<typeof ZMatchInfoSchema>;

export const ZAgentSchema = z
	.string()
	.transform((x) =>
		x
			.replace("_PC_C", "")
			.replace("_PostDeath", "")
			.replace("Pawn_Hunter_E_Drone_C", ValorantAgent.Sova)
			.replace("Pawn_Guide_Q_PossessableScout_C", ValorantAgent.Skye)
			.replace("Pawn_Gumshoe_Q_PossessableCamera_C", ValorantAgent.Cypher),
	)
	.transform((x) => Object.values(ValorantAgent).find((y) => y === x))
	.optional()
	.nullable();

export const ZAbilitiesSchema = z.object({
	C: z.boolean(),
	Q: z.boolean(),
	E: z.boolean(),
	X: z.boolean(),
});

export const ZMeSchema = z.object({
	player_name: ZPlayerFullName.optional(),
	player_id: z.string().optional(),
	region: z.string().optional(),
	agent: ZAgentSchema,
	health: z.coerce.number().optional().nullable(),
	abilities: z
		.string()
		.transform((x) => ZAbilitiesSchema.parse(JSON.parse(x)))
		.optional()
		.nullable(),
});

export const ZValorantScene = z
	.nativeEnum(ValorantMap)
	.or(z.nativeEnum(ScenesThatArentMaps));

export type IValorantScene = z.infer<typeof ZValorantScene>;

export const ZGameInfoSchema = z.object({
	scene: ZValorantScene.optional(),
	state: z.nativeEnum(ClientState).optional(),
	is_pbe: z.coerce.boolean().optional().nullable(),
});

export const ZKillSchema = z.object({
	kills: z
		.string()
		.transform((x) => parseInt(x))
		.nullable()
		.optional(),
	assists: z
		.string()
		.transform((x) => parseInt(x))
		.nullable()
		.optional(),
	headshots: z
		.string()
		.transform((x) => parseInt(x))
		.nullable()
		.optional(),
});

export const ZDeathSchema = z.object({
	deaths: z
		.string()
		.transform((x) => parseInt(x))
		.nullable(),
});

export const ZEventSchema = z.discriminatedUnion("name", [
	z.object({ name: z.literal(OWValorantEvent.matchStart), data: z.string() }),
	z.object({ name: z.literal(OWValorantEvent.matchEnd), data: z.string() }),
	z.object({
		name: z.literal(OWValorantEvent.kill),
		data: z.string().transform((x) => Number.parseInt(x)),
	}),
	z.object({
		name: z.literal(OWValorantEvent.assist),
		data: z.string().transform((x) => Number.parseInt(x)),
	}),
	z.object({
		name: z.literal(OWValorantEvent.headshot),
		data: z.string().transform((x) => Number.parseInt(x)),
	}),
	z.object({
		name: z.literal(OWValorantEvent.death),
		data: z.string().transform((x) => Number.parseInt(x)),
	}),
	z.object({ name: z.literal(OWValorantEvent.spikePlanted), data: z.string() }),
	z.object({ name: z.literal(OWValorantEvent.spikeDefused), data: z.string() }),
	z.object({
		name: z.literal(OWValorantEvent.spikeDetonated),
		data: z.string(),
	}),
	z.object({
		name: z.literal(OWValorantEvent.killFeed),
		data: ZKillFeedTransform,
	}),
	z.object({
		name: z.literal(OWValorantEvent.shop),
		data: z.nativeEnum(ShopEventData),
	}),
	z.object({
		name: z.literal(OWValorantEvent.scoreboardScreen),
		data: z.literal("open").or(z.literal("close")),
	}),
]);

export const ZOWSchema = z.object({
	feature: z.string().optional(),
	info: z
		.object({
			me: ZMeSchema.optional(),
			match_info: ZMatchInfoSchema.optional(),
			game_info: ZGameInfoSchema.optional(),
			kill: ZKillSchema.optional(),
			death: ZDeathSchema.optional(),
		})
		.optional(),
	events: z.array(ZEventSchema).optional(),
	timestamp: z.number(),
	id: z.string(),
});

export type OWData = z.infer<typeof ZOWSchema>;

export function getScoreboardItemsFromMatchInfo(
	matchInfo: IMatchInfo,
): IScoreboard[] {
	const scoreboardItems: IScoreboard[] = [];

	Object.entries(matchInfo).forEach(([key, value]) => {
		if (key.startsWith("scoreboard_")) {
			if (typeof value === "string") {
				scoreboardItems.push(ZScoreboardTransform.parse(value));
			} else if (typeof value === "object") {
				scoreboardItems.push(value as IScoreboard);
			}
		}
	});

	return scoreboardItems;
}

export function getRosterItemsFromMatchInfo(
	matchInfo: IMatchInfo,
): IRosterPlayer[] {
	const rosterItems: IRosterPlayer[] = [];

	Object.entries(matchInfo).forEach(([key, value]) => {
		if (key.startsWith("roster_")) {
			if (typeof value === "string") {
				rosterItems.push(ZRosterTransform.parse(value));
			} else if (typeof value === "object") {
				rosterItems.push(value as IRosterPlayer);
			}
		}
	});

	return rosterItems;
}
