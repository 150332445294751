import { z } from "zod";

export const GetInsightArgs = z.object({
	prompt: z.string(),
});

export type GetInsightArgs = z.infer<typeof GetInsightArgs>;

export const GetInsightRequest = z.object({
	body: GetInsightArgs,
});
