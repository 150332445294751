import { z } from 'zod';
import { ZEntity } from '../../_base';
import { MatchOutcomes, Ranks, RoundOutcome, Team, ValorantAgent, ValorantMap, ZWeapon } from '../../../types';
import { GameMode, MatchAnalysisTitle, MatchPlayerAnalysisTitle } from '../../../enums';

export const ZMatch = ZEntity.extend({
  userId: z.string(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date().nullable().optional(),
  outcome: z.nativeEnum(MatchOutcomes).nullable().optional(),
  agent: z.nativeEnum(ValorantAgent).or(z.literal('Unknown')),
  map: z.nativeEnum(ValorantMap).or(z.literal('Unknown')),
  startingTeam: z.nativeEnum(Team).or(z.literal('Unknown')),
  kills: z.number().nullable().optional(),
  deaths: z.number().nullable().optional(),
  assists: z.number().nullable().optional(),
  roundsWon: z.number().nullable().optional(),
  roundsLost: z.number().nullable().optional(),
  guardianScore: z.number().nullable().optional(),
  playerRank: z.nativeEnum(Ranks).or(z.literal('Unknown')),
  gameMode: z.nativeEnum(GameMode).or(z.literal('Unknown')),
  isSurrendered: z.boolean().nullable().optional(),
  bestWeapon: ZWeapon.or(z.literal('Unknown')).nullable().optional(),
  gameTag: z.string().nullable().optional(),
  tagline: z.string().nullable().optional(),
  riotMatchId: z.string().optional().nullable(),
  analysisTitle: z.nativeEnum(MatchAnalysisTitle).nullable().optional(),
});

export type IMatch = z.infer<typeof ZMatch>;

export const ZMatchPlayer = z.object({
  playerId: z.string(),
  agent: z.nativeEnum(ValorantAgent).or(z.literal('Unknown')),
  isTeammate: z.boolean(),
  guardianScore: z.number(),
  rank: z.nativeEnum(Ranks).or(z.literal('Unknown')),
  matchId: z.string(),
  kills: z.number().default(0),
  deaths: z.number().default(0),
  assists: z.number().default(0),
  bestWeapon: ZWeapon.or(z.literal('Unknown')).default('Unknown'),
  gameTag: z.string().optional().nullable(),
  tagline: z.string().optional().nullable(),
  analysisTitle: z.nativeEnum(MatchPlayerAnalysisTitle).optional().nullable(),
  riotPuuid: z.string().optional().nullable(),
  isGuardianPlayer: z.boolean().optional().nullable(),
});

export type IMatchPlayer = z.infer<typeof ZMatchPlayer>;

export const ZMatchRound = z.object({
  id: z.string(),
  roundOutcome: z.nativeEnum(RoundOutcome),
  roundNumber: z.number(),
  matchId: z.string(),
  team: z.nativeEnum(Team).or(z.literal('Unknown')),
  roundTime: z.number(),
  guardianScoreDelta: z.number(),
  spikePlantedTimestamp: z.number().optional().nullable(),
});

export type IMatchRound = z.infer<typeof ZMatchRound>;
