import { z } from "zod";
import {
	ResolutionSizeType,
	SystemMessageReply,
	UserActivityType,
	WidgetType,
	VideoAdContainerEvent,
	UserOnboardingStatus,
	OnboardingSequenceType,
	PatternType,
	GameSessionRecommendationTypes,
	GameSessionCheckpointTypes,
	OverwolfAdEventType,
} from "../../../enums";
import { ZMatch } from "../../match";
import { ZUser } from "../../user";

export const ZMatchEndAnalyticData = z.object({
	gameMode: ZMatch.shape.gameMode,
	map: ZMatch.shape.map,
	rank: ZMatch.shape.playerRank,
	guardianScore: ZMatch.shape.guardianScore,
	outcome: ZMatch.shape.outcome,
});

export type IMatchEndAnalyticData = z.infer<typeof ZMatchEndAnalyticData>;

export const ZMatchStartAnalyticData = ZMatchEndAnalyticData.omit({
	guardianScore: true,
	outcome: true,
});

export type IMatchStartAnalyticData = z.infer<typeof ZMatchStartAnalyticData>;

export const ZSignupAnalyticData = z.object({
	isGuest: ZUser.shape.isGuest,
	group: ZUser.shape.group,
	userName: ZUser.shape.userName,
	email: ZUser.shape.email.optional(),
	isReferred: z.boolean(),
});

export type ISignupAnalyticData = z.infer<typeof ZSignupAnalyticData>;

export const ZPeriodEndInfo = z.object({
	windowName: z.string(),
	periodDuration: z.number(),
	visible: z.object({
		totalDuration: z.number(),
		totalEntries: z.number(),
		totalExits: z.number(),
	}),
	idle: z.object({
		totalDuration: z.number(),
		totalEntries: z.number(),
		totalExits: z.number(),
	}),
	active: z.object({
		totalDuration: z.number(),
		totalEntries: z.number(),
		totalExits: z.number(),
	}),
	hidden: z.object({
		totalDuration: z.number(),
		totalEntries: z.number(),
		totalExits: z.number(),
	}),
	focused: z.object({
		totalDuration: z.number(),
		totalEntries: z.number(),
		totalExits: z.number(),
	}),
	blurred: z.object({
		totalDuration: z.number(),
		totalEntries: z.number(),
		totalExits: z.number(),
	}),
});

export type IPeriodEndInfo = z.infer<typeof ZPeriodEndInfo>;

// Must have user id
export const ZTrackUserActivityExternalArgs = z.discriminatedUnion("type", [
	z.object({
		type: z.literal(UserActivityType.click_Auth_LoginWithOverwolf),
		data: z.object({
			userId: z.string(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Auth_LoginAsGuest),
		data: z.object({
			userId: z.string(),
		}),
	}),
]);

export type ITrackUserActivityExternalArgs = z.infer<
	typeof ZTrackUserActivityExternalArgs
>;

export const ZTrackUserActivityExternalRequest = z.object({
	body: ZTrackUserActivityExternalArgs,
});

export const ZTrackUserActivityArgs = z.discriminatedUnion("type", [
	z.object({
		type: z.literal(UserActivityType.click_Auth_LoginWithOverwolf),
		data: z.object({
			userId: z.string(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Auth_LoginAsGuest),
		data: z.object({
			userId: z.string(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Onboarding_ClickSequence),
		data: z.object({
			sequence: z.nativeEnum(OnboardingSequenceType),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Onboarding_StatusChange),
		data: z.object({
			status: z.nativeEnum(UserOnboardingStatus),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_MatchLive_Initialize),
		data: z.object({
			numberOfScreens: z.number(),
			monitorSizes: z.string().array(),
			hasMonitorOnRight: z.boolean().optional(),
			hasMonitorOnLeft: z.boolean().optional(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Ads_ContainerInitialize),
		data: z.object({
			success: z.boolean(),
			error: z.string().optional(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Ads_OverwolfEvent),
		data: z.object({
			eventType: z.nativeEnum(OverwolfAdEventType),
			windowName: z.string().optional(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Ads_VideoPlayerEvent),
		data: z.object({
			event: z.nativeEnum(VideoAdContainerEvent),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.click_AppUpdate_Update),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_PlayButton_Click),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_AppUpdate_Restart),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Misc_Contribute),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Activity_PeriodEnd),
		data: ZPeriodEndInfo,
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Updates_Open),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Updates_ChangeTab),
		data: z.object({
			from: z.string(),
			to: z.string(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Match_Start),
		data: ZMatchStartAnalyticData,
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Match_End),
		data: ZMatchEndAnalyticData,
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Auth_Signup),
		data: ZSignupAnalyticData,
	}),
	z.object({
		type: z.literal(UserActivityType.audit_App_FirstLaunch),
		data: z.object({
			isLoggedInToOverwolf: z.boolean(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_App_Uninstall),
		data: z.object({
			overwolfUserId: z.string().optional(),
			overwolfUsername: z.string().optional(),
			userId: ZUser.shape.id,
			userName: ZUser.shape.userName,
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Auth_Login),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Auth_ConvertAccount),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Settings_Update),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Auth_Logout),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Auth_Logout),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_ShareImage_Copy),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_ShareImage_ChangeColor),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_ShareImage_ChangeAgent),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_ShareImage_Enter),
		data: z.object({
			agentCount: z.number(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_ShareImage_Leave),
		data: z.object({
			agentCount: z.number(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_ReportBug_Enter),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_ReportBug_Leave),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_ReportBug_Send),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_ReportBug),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Socials_DiscordInvite),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Settings_Enter),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Settings_Leave),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Settings),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Settings_ChangeResolution),
		data: z.object({
			oldResolution: z.nativeEnum(ResolutionSizeType).optional(),
			newResolution: z.nativeEnum(ResolutionSizeType),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Settings_ToggleInGameOverlay),
		data: z.object({
			to: z.boolean(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Settings_ToggleInGameUltStatus),
		data: z.object({
			to: z.boolean(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Settings_ToggleSpikeTimer),
		data: z.object({
			to: z.boolean(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Guide_Enter),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Guide_Leave),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Guide_SelectBehaviour),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_MatchHistory_Enter),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_MatchHistory_Leave),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_MatchHistory_Item),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Homepage_Enter),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Homepage_Leave),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Widget_Like),
		data: z.object({
			widgetType: z.nativeEnum(WidgetType),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.click_ShareApp_Copy),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Game_Open),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Game_Close),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Game_Running),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Hotkey_ToggleMatchLive),
		data: z.object({
			isOn: z.boolean(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Hotkey_SkipInGame),
		data: z.object({
			isMidGameTipsVisible: z.boolean(),
			isAgentSelectVisible: z.boolean(),
			currentInGameGuideline: z.string().optional(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Broadcast_DismissMandatoryMessage),
		data: z.object({
			reply: z.nativeEnum(SystemMessageReply),
			systemMessageId: z.string(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Info_Enter),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Info_Leave),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_InGame_AgentSelectEnter),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_InGame_AgentSelectExit),
		data: z.object({
			openTime: z.number(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_InGame_TopAgentsForMapEnter),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_InGame_TopAgentsForMapExit),
		data: z.object({
			openTime: z.number(),
			isVisible: z.boolean(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Dashboard_AddItem),
		data: z.object({
			widgetType: z.nativeEnum(WidgetType),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Dashboard_OpenContextActions),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Dashboard_AddContextAction),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Dashboard_RemoveItemDrag),
		data: z.object({
			widgetType: z.nativeEnum(WidgetType),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Dashboard_RemoveItem),
		data: z.object({
			widgetType: z.nativeEnum(WidgetType),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Dashboard_MoveItem),
		data: z.object({
			widgetType: z.nativeEnum(WidgetType),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Store_Enter),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Store_Buy),
		data: z.object({
			name: z.string(),
			widgetType: z.nativeEnum(WidgetType),
			price: z.number(),
			likes: z.number(),
			size: z.string(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Store_BuyDecline),
		data: z.object({
			name: z.string(),
			widgetType: z.nativeEnum(WidgetType),
			price: z.number(),
			likes: z.number(),
			size: z.string(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Store_BuyAccept),
		data: z.object({
			name: z.string(),
			widgetType: z.nativeEnum(WidgetType),
			price: z.number(),
			likes: z.number(),
			size: z.string(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Store_BuySuccess),
		data: z.object({
			name: z.string(),
			widgetType: z.nativeEnum(WidgetType),
			price: z.number(),
			likes: z.number(),
			size: z.string(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Pattern_Shown),
		data: z.object({
			patternType: z.nativeEnum(PatternType),
			isSkipped: z.boolean().optional(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Pattern_Skipped),
		data: z.object({
			patternType: z.nativeEnum(PatternType),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Pattern_Recognized),
		data: z.object({
			patternType: z.nativeEnum(PatternType),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Pattern_Matched),
		data: z.object({
			patternType: z.nativeEnum(PatternType),
		}),
	}),
	z.object({
		type: z.literal(
			UserActivityType.click_Widget_PerformanceStrengths_NextArrow,
		),
		data: z.object({
			direction: z.literal("left").or(z.literal("right")),
		}),
	}),
	z.object({
		type: z.literal(
			UserActivityType.click_Widget_PerformanceStrengths_ClickNavigationCircle,
		),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(
			UserActivityType.click_Widget_PerformanceWeaknesses_NextArrow,
		),
		data: z.object({
			direction: z.literal("left").or(z.literal("right")),
		}),
	}),
	z.object({
		type: z.literal(
			UserActivityType.click_Widget_PerformanceWeaknesses_ClickNavigationCircle,
		),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Widget_View),
		data: z.object({
			widgetType: z.nativeEnum(WidgetType),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_UltHelper_Enter),
		data: z.null().optional(),
	}),

	z.object({
		type: z.literal(UserActivityType.audit_MatchSummary_EnterMinimized),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_MatchSummary_Show),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_MatchSummary_Skip),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_MatchSummary_Compare),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_MatchSummary_Performance),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_MatchSummary_Close),
		data: z.object({
			timeOpen: z.number(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.hotkey_MatchSummary_Compare),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.hotkey_MatchSummary_Performance),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.hotkey_MatchSummary_Close),
		data: z.object({
			timeOpen: z.number(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_GameSession_Checkpoint),
		data: z.object({
			type: z.nativeEnum(GameSessionCheckpointTypes),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_GameSession_Recommendation),
		data: z.object({
			type: z.nativeEnum(GameSessionRecommendationTypes),
			data: z.any(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_GameSession_Recommendation_Skip),
		data: z.object({
			type: z.nativeEnum(GameSessionRecommendationTypes),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_GameSession_Start),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_GameSession_End),
		data: z.object({
			sessionLength: z.number(),
			matchesPlayed: z.number(),
			warmupsPlayed: z.number(),
		}),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Lootopia_Download),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.audit_Lootopia_Enroll),
		data: z.null().optional(),
	}),
	z.object({
		type: z.literal(UserActivityType.click_Lootopia_Enroll),
		data: z.null().optional(),
	}),
]);

export type ITrackUserActivityArgs = z.infer<typeof ZTrackUserActivityArgs>;

export const ZTrackUserActivityRequest = z.object({
	body: ZTrackUserActivityArgs,
});

export const ZTrackFirstLaunchArgs = z.object({
	userId: z.string(),
	isLoggedInToOverwolf: z.boolean(),
});

export const ZTrackFirstLaunchRequest = z.object({
	body: ZTrackFirstLaunchArgs,
});

export type ITrackFirstLaunchArgs = z.infer<typeof ZTrackFirstLaunchArgs>;
