import { z } from "zod";

export enum GiveawayStatus {
	active = "active",
	ended = "ended",
	disabled = "disabled",
}

export const Giveaway = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string().optional().nullable(),
	startDate: z.coerce.date(),
	endDate: z.coerce.date(),
	prize: z.string(),
	imageUrl: z.string(),
	bannerUrl: z.string(),
	winnerUserId: z.string().optional().nullable(),
	entryFee: z.number(),
	status: z.nativeEnum(GiveawayStatus),
	isInGiveaway: z.coerce.boolean(),
	participants: z.coerce.number(),
});

export type Giveaway = z.infer<typeof Giveaway>;

export const EnterGiveawayArgs = z.object({
	giveawayId: z.string(),
	entryFee: z.number(),
});

export type EnterGiveawayArgs = z.infer<typeof EnterGiveawayArgs>;

export const EnterGiveawayRequest = z.object({
	body: EnterGiveawayArgs,
});

export enum UserGiveawayState {
	idle = "idle",
	winner = "winner",
	seen = "seen",
	delivered = "delivered",
}

export const UserGiveawayWin = z.object({
	giveawayId: Giveaway.shape.id,
	giveawayName: Giveaway.shape.name,
	giveawayImageUrl: Giveaway.shape.imageUrl,
	giveawayBannerUrl: Giveaway.shape.bannerUrl,
	giveawayPrize: Giveaway.shape.prize,
	state: z.nativeEnum(UserGiveawayState),
	winCode: z.string(),
});

export type UserGiveawayWin = z.infer<typeof UserGiveawayWin>;
